import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { Hr, SkillCard } from '../components'
import { SkillsData, SliderSettings } from '../data'
import { IDimensions } from '../types'

export const Skills = ({ width }: IDimensions) => {
    return (
        <div id="skills" className="mt-4 scroll-m-24">
            <h1 className="text-2xl font-bold text-dark-500 dark:text-white">
                Skills
            </h1>
            <p className="font-light text-dark-500 dark:text-light-400">
                Here are some of my skills
            </p>

            <div className="mt-4">
                <Slider {...SliderSettings(width)}>
                    {SkillsData.map(({ name, img, experience }, index) => (
                        <SkillCard
                            key={index}
                            name={name}
                            experience={experience}
                            img={img}
                        />
                    ))}
                </Slider>
            </div>
            <Hr className="mt-8 w-full md:h-3" />
        </div>
    )
}
