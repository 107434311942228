import { ICertificationCard } from '../types'

export const CertificationCard = ({
    date,
    desc,
    href,
    issued,
    name,
}: ICertificationCard) => {
    return (
        <a href={href} target="_blank" rel="noreferrer">
            <div
                className="hover:bg-dark flex h-full w-full flex-col
justify-start rounded-md bg-light-500 py-4 px-4 dark:bg-dark-100"
            >
                <div className="overflow-hidden rounded-sm">
                    <img
                        src={href}
                        className="mx-auto h-56 w-full object-cover"
                        alt={name}
                    />
                </div>
                <div className="mt-2 flex flex-1 flex-col justify-start font-light">
                    <h1 className="flex-1 font-bold text-dark-500 dark:text-white md:text-xl">
                        {name}
                    </h1>
                    <p className="text-dark-500 dark:text-white md:text-lg">
                        {issued}
                    </p>
                    <p className="text-dark-200 dark:text-light-400">{date}</p>
                    <p className="text-dark-200 dark:text-light-400">{desc}</p>
                </div>
            </div>
        </a>
    )
}
