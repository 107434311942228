import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IAccount } from '../types'

export const Account = ({ url, icon, hoverColor }: IAccount) => (
    <li className={`transition-all duration-300 hover:scale-125 ${hoverColor}`}>
        <a href={url} rel="noreferrer" target="_blank">
            <FontAwesomeIcon size="2xl" icon={icon} className="" />
        </a>
    </li>
)
