import { resume } from '../assets'

export const Resume = () => (
    <a
        href={resume}
        rel="noreferrer"
        target="_blank"
        className="rounded-full border-4 border-teal-800 bg-teal-500 px-4 py-1"
    >
        Resume
    </a>
)
