import { Copyright, Hr, NavbarItem } from '../components'
import { NavbarItems } from '../data'

export const Footer = () => {
    return (
        <div className="mt-4 rounded-md bg-light-500 px-8 py-4 text-white dark:bg-dark-100">
            <ul className="text-center">
                {NavbarItems.map(({ href, title }, index) => (
                    <NavbarItem
                        key={index}
                        href={href}
                        title={title}
                        className="text-white dark:text-light-200"
                    />
                ))}
            </ul>
            <Hr className="bottom-0 mb-4 mt-4 w-full text-white md:h-1" />
            <Copyright />
        </div>
    )
}
