import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Themes } from '../types'

export function ThemeSwitcher({
    theme,
    setTheme,
}: {
    theme: string
    setTheme: () => void
}) {
    return (
        <div className="" onClick={setTheme}>
            {theme === Themes.LIGHT ? (
                <FontAwesomeIcon
                    icon={faSun}
                    size={'2x'}
                    className="cursor-pointer text-yellow-500 transition-all hover:text-yellow-600"
                />
            ) : (
                <FontAwesomeIcon
                    icon={faMoon}
                    size={'2x'}
                    className="cursor-pointer text-white transition-all hover:text-light-300"
                />
            )}
        </div>
    )
}
