import { useState } from 'react'

import { profile2, profileDark } from '../assets'
import { Account, Hr } from '../components'
import { AboutMeData, AccountsData, MajorData, NameData } from '../data'
import { Themes } from '../types'

export const Hero = ({ theme }: { theme: Themes }) => {
    const [loaded, setLoaded] = useState(true)

    return (
        <>
            {loaded ? (
                <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-full flex-col items-center justify-center overflow-hidden bg-white">
                    Loading...
                </div>
            ) : null}
            <div
                id="home"
                className="relative flex h-screen w-full flex-col items-center justify-center gap-5 text-black dark:text-white md:flex-row"
            >
                <div className="md:w-3/6 md:p-4">
                    <img
                        src={theme === Themes.LIGHT ? profile2 : profileDark}
                        alt="profile"
                        onLoad={() => setLoaded(false)}
                    />
                </div>
                <div className="md:w-3/6">
                    <div className="mt-8 flex w-full flex-col">
                        <h1 className="text-xl text-dark-500 dark:text-light-400">
                            Hi, I'm
                        </h1>
                        <h1 className="text-2xl font-bold text-dark-500 dark:text-light-100">
                            {NameData}
                        </h1>
                        <p className="text-md font-bold text-dark-50 dark:text-light-300">
                            {MajorData}
                        </p>
                        <p className="text-md font-light text-dark-200 dark:text-light-300">
                            {AboutMeData}
                        </p>
                    </div>

                    <ul className="mt-2 flex items-center gap-4">
                        {AccountsData.map(
                            ({ icon, url, hoverColor }, index) => (
                                <Account
                                    key={index}
                                    icon={icon}
                                    url={url}
                                    hoverColor={hoverColor}
                                />
                            ),
                        )}
                    </ul>
                </div>
                <Hr />
            </div>
        </>
    )
}
