import { Hr, ProjectCard } from '../components'
import { ProjectsData } from '../data'

export const Projects = () => {
    return (
        <div id="projects" className="mt-4 scroll-m-24">
            <h1 className="text-2xl font-bold text-dark-500 dark:text-white">
                Projects
            </h1>
            <p className="font-light text-dark-500 dark:text-light-400">
                Here are some of my Projects
            </p>

            {/* <div className="flex flex-col md:flex-row flex-wrap mt-4 gap-5"> */}
            <div className="mt-4 grid grid-cols-1 justify-center gap-5 md:grid-cols-3">
                {ProjectsData.map(
                    (
                        {
                            date,
                            href,
                            issued,
                            name,
                            desc,
                            appStoreUrl,
                            googlePlayUrl,
                            cover,
                        },
                        index,
                    ) => (
                        <ProjectCard
                            key={index}
                            date={date}
                            issued={issued}
                            href={href}
                            name={name}
                            desc={desc}
                            appStoreUrl={appStoreUrl}
                            googlePlayUrl={googlePlayUrl}
                            cover={cover}
                        />
                    ),
                )}
            </div>
            <Hr className="mt-8 w-full md:h-2" />
        </div>
    )
}
