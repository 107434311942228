import { faAppStore, faGooglePlay } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IProjectCard } from '../types'

export const ProjectCard = ({
    date,
    desc,
    href,
    issued,
    name,
    appStoreUrl,
    googlePlayUrl,
    cover,
}: IProjectCard) => {
    console.log('🚀 ~ file: ProjectCard.tsx:15 ~ cover:', cover)
    return (
        <div
            className="hover:bg-dark flex h-full w-full flex-col
justify-start rounded-md bg-light-500 py-4 px-4 dark:bg-dark-100"
        >
            <div className="rounded-sm bg-white">
                <img
                    src={href}
                    className={`mx-auto h-56 w-full ${
                        cover ? 'object-cover' : 'object-contain'
                    }`}
                    alt={name}
                />
            </div>
            <div className="mt-2 flex-1 font-light">
                <h1 className="font-bold text-dark-500 dark:text-white md:text-xl">
                    {name}
                </h1>
                <div className="mb-3 flex justify-between">
                    <p className="text-dark-500 dark:text-white md:text-lg">
                        For {issued}
                    </p>
                    <p className="text-white dark:text-light-400">{date}</p>
                </div>
                <p className="text-white dark:text-light-300">{desc}</p>
            </div>
            {appStoreUrl || googlePlayUrl ? (
                <div className="mt-5 flex justify-end">
                    {appStoreUrl ? (
                        <a
                            href={appStoreUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="mx-1 text-dark-500 transition-all duration-100 hover:opacity-75 dark:text-white"
                        >
                            <FontAwesomeIcon
                                size="2xl"
                                icon={faAppStore}
                                className=""
                            />
                        </a>
                    ) : null}
                    {googlePlayUrl ? (
                        <a
                            href={googlePlayUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="mx-1 text-dark-500 transition-all duration-100 hover:opacity-75 dark:text-white"
                        >
                            <FontAwesomeIcon
                                size="2xl"
                                icon={faGooglePlay}
                                className=""
                            />
                        </a>
                    ) : null}
                </div>
            ) : null}
        </div>
    )
}
