import { INavbarItem } from '../types'

export const NavbarItem = ({
    title,
    href,
    className = 'text-black dark:text-white',
}: INavbarItem) => (
    <li className="p-4">
        <a href={href} className={`hover:underline ${className}`}>
            {title}
        </a>
    </li>
)
