import { ISkillCard } from '../types'

export const SkillCard = ({ experience, img, name }: ISkillCard) => {
    return (
        <div className="flex h-48 w-full flex-col justify-between rounded-md bg-light-500 p-4 text-center dark:bg-dark-100 md:w-11/12">
            <img src={img} className="mx-auto max-h-20" alt={name}></img>
            <div className="mt-2 text-dark-500 dark:text-white">
                <h1 className="font-bold md:text-xl">{name}</h1>
                <p
                    className={`font-light md:text-lg ${
                        !experience && 'invisible'
                    }`}
                >
                    {experience} of experience
                </p>
            </div>
        </div>
    )
}
