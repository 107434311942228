import {
    faFacebook,
    faGithub,
    faInstagram,
    faLinkedinIn,
    faGitlab,
} from '@fortawesome/free-brands-svg-icons'
import { faMessage, faPhone } from '@fortawesome/free-solid-svg-icons'
import { Settings } from 'react-slick'

import {
    adinusa,
    arto,
    bloc,
    cadeauBoutique,
    cadeuaDelivery,
    caprianiGelato,
    casakai,
    travelTent,
    cleanCode,
    designPatterns,
    firebase,
    flutter,
    getx,
    git,
    googleMap,
    linux,
    paymentMethod,
} from './assets'

import {
    IAccount,
    IAwardsCard,
    ICertificationCard,
    INavbarItem,
    IProjectCard,
    ISkillCard,
} from './types'

export const SliderSettings = (width: number): Settings => ({
    dots: false,
    autoplay: true,
    infinite: true,
    slidesToShow: width > 770 ? 2 : 1,
    slidesToScroll: 1,
    arrows: false,
})

export const NavbarItems: INavbarItem[] = [
    {
        title: 'About',
        href: '#home',
    },
    {
        title: 'Skills',
        href: '#skills',
    },
    // {
    //     title: 'Awards',
    //     href: '#awards',
    // },
    {
        title: 'Certifications',
        href: '#certifications',
    },
    {
        title: 'Projects',
        href: '#projects',
    },
]

export const NameData = 'Ahmad Kol Agha'
export const MajorData = 'Mobile App Developer'
export const AboutMeData = `An Experienced Mobile Application Developer with a demonstrated history of working in the information
technology and services industry. Skilled in Flutter (Framework), Firebase, Clean Code, state
management (BLoC, GetX), and Databases (SQL, NoSQL). Strong engineering professional with a
Bachelor's degree focused in Computer Engineering from Tishreen University.`
export const AccountsData: IAccount[] = [
    {
        url: 'https://www.facebook.com/ahmadkolagha',
        icon: faFacebook,
        hoverColor: 'hover:text-blue-500',
    },
    {
        url: 'https://gitlab.com/ahmad_agha',
        icon: faGitlab,
        hoverColor: 'hover:text-orange-500',
    },
    {
        url: 'https://www.instagram.com/ahmadkolagha',
        icon: faInstagram,
        hoverColor: 'hover:text-red-500',
    },
    {
        url: 'https://www.linkedin.com/in/ahmad-kol-agha-1770701ba',
        icon: faLinkedinIn,
        hoverColor: 'hover:text-blue-500',
    },
    {
        url: 'mailto:ahmadkolagha@ka4.tech',
        icon: faMessage,
        hoverColor: 'hover:text-yellow-500',
    },
    {
        url: 'tel:+963943984490',
        icon: faPhone,
        hoverColor: 'hover:text-gray-500',
    },
]

export const SkillsData: ISkillCard[] = [
    {
        name: 'BLoC',
        experience: '3 years',
        img: bloc,
    },
    {
        name: 'GetX',
        experience: '3 years',
        img: getx,
    },
    {
        name: 'Firesbase',
        experience: '3 years',
        img: firebase,
    },
    {
        name: 'Google maps dev',
        experience: '3 years',
        img: googleMap,
    },
    {
        name: 'integration with a payment method',
        experience: '2 years',
        img: paymentMethod,
    },
    {
        name: 'Hive, Sqflite, Storage Getx',
        experience: '3 years',
        img: flutter,
    },
    {
        name: 'Git',
        // experience: '3 years',
        img: git,
    },
    {
        name: 'Linux',
        // experience: '3 years',
        img: linux,
    },
    {
        name: 'Design pattern',
        // experience: '3 years',
        img: designPatterns,
    },
    {
        name: 'Clean code',
        // experience: '3 years',
        img: cleanCode,
    },
]

export const AwardsData: IAwardsCard[] = [
    {
        name: 'Top 10 SELEKNAS ASEAN Skills Competition ITNSA 2022',
        issued: 'KEMENAKER RI',
        desc: 'Linux Server, Windows Server, Linux Routing, Cisco Troubleshooting, Cisco Network Analysis, Network and System Programmabillity, Containerized Service.',
    },
    {
        name: 'Top 10 SELEKNAS ASEAN Skills Competition ITNSA 2022',
        issued: 'KEMENAKER RI',
        desc: 'Linux Server, Windows Server, Linux Routing, Cisco Troubleshooting, Cisco Network Analysis, Network and System Programmabillity, Containerized Service.',
    },
    {
        name: 'Top 10 SELEKNAS ASEAN Skills Competition ITNSA 2022',
        issued: 'KEMENAKER RI',
        desc: 'Linux Server, Windows Server, Linux Routing, Cisco Troubleshooting, Cisco Network Analysis, Network and System Programmabillity, Containerized Service.',
    },
    {
        name: 'Top 10 SELEKNAS ASEAN Skills Competition ITNSA 2022',
        issued: 'KEMENAKER RI',
        desc: 'Linux Server, Windows Server, Linux Routing, Cisco Troubleshooting, Cisco Network Analysis, Network and System Programmabillity, Containerized Service.',
    },
    {
        name: 'Top 10 SELEKNAS ASEAN Skills Competition ITNSA 2022',
        issued: 'KEMENAKER RI',
        desc: 'Linux Server, Windows Server, Linux Routing, Cisco Troubleshooting, Cisco Network Analysis, Network and System Programmabillity, Containerized Service.',
    },
]

export const CertificationsData: ICertificationCard[] = [
    {
        name: "Bachelor's of Computer and Automatic Control Engineering",
        href: adinusa,
        issued: 'Tishreen University',
        date: '2017, 2022',
    },
    {
        name: 'Master of Web Science',
        href: adinusa,
        issued: 'Syrian Virtual University',
        date: '2022, ongoing',
    },
]

export const ProjectsData: IProjectCard[] = [
    {
        name: 'Arto',
        href: arto,
        issued: 'Arto (freelancer)',
        desc: `I made these changes to the app: Add guest mode, Redesigning the main homepage to make it more attractive and comprehensive, Solve connection issues on some pages, view services in the Service Provider section for browsing, share files of service providers' pages and personal pages via dynamic links, and the ability to go to them directly, add a network update tool when the Internet is out, support for internal notification signs and updating them in requests and chats, solve many bugs in the app and Improved app design.`,
        appStoreUrl: 'https://apps.apple.com/sa/app/arto/id1608210325',
        googlePlayUrl:
            'https://play.google.com/store/apps/details?id=net.smartangle.arto',
    },
    {
        name: 'Travel Tent',
        href: travelTent,
        issued: 'BW',
        desc: `Travel Tent—a comprehensive travel application. My role encompassed end-to-end, state management using BLoC, API integration, and features like Google Maps, social media login, secure payments, and Firebase notifications. My commitment to the project extended beyond launch, providing a half year of continuous support and development, ensuring Travel Tent's ongoing success.`,
        appStoreUrl:
            'https://apps.apple.com/us/app/travel-tent/id6463473995?platform=iphone',
        googlePlayUrl:
            'https://play.google.com/store/apps/details?id=com.bw.travel',
        cover: true,
    },
    {
        name: 'Cadeau Boutique',
        href: cadeauBoutique,
        issued: 'BW',
        desc: `E-store that allows choosing several gifts with timely delivery to the desired address. Implementation of the mobile application from A to Z. Designing all application interfaces, using BLoC for state management, and connecting the application with the API. The application contains Google Maps, log-in with social media, integration with a payment method, and Firebase notifications. Support and development of the application for a year and a half.`,
        appStoreUrl:
            'https://apps.apple.com/us/app/cadeaue-boutique/id1573106431?platform=iphone',
        googlePlayUrl:
            'https://play.google.com/store/apps/details?id=com.smarttouch.cadeaue_boutique',
    },
    {
        name: 'Cadeau Driver',
        href: cadeuaDelivery,
        issued: 'BW',
        desc: `Application for delivery drivers Cadeau Boutique, For deliver gifts and make sure they are delivered safely. Implementation of the mobile application from A to Z. Designing all application interfaces, using GetX for state management, and connecting the application with the API. The application contains Google Maps, a QR scanner, and Firebase notifications.`,
        appStoreUrl:
            'https://apps.apple.com/us/app/cadeau-driver/id1671733246?platform=iphone',
        googlePlayUrl:
            'https://play.google.com/store/apps/details?id=com.bw.cadeuadelivery.cadeua_delivery',
    },
    {
        name: 'Capriani Gelato',
        href: caprianiGelato,
        issued: 'BW',
        desc: `Implementation of the mobile application from A to Z. Designing all application interfaces, using BLoC for state management, and connecting the application with the API. `,
        appStoreUrl:
            'https://apps.apple.com/app/capriani-gelato/id1601074577?platform=iphone',
    },
    {
        name: 'Casakai',
        href: casakai,
        issued: 'BW',
        desc: `Implementation of the mobile application from A to Z. Designing all application interfaces, using BLoC for state management, and connecting the application with the API.`,
        appStoreUrl:
            'https://apps.apple.com/us/app/casakai/id1600065301?platform=iphone',
    },
]
