import { NavbarItem, Resume } from '../components'
import { ThemeSwitcher } from '../components'
import { NavbarItems } from '../data'

export const Navbar = ({
    theme,
    setTheme,
}: {
    theme: string
    setTheme: () => void
}) => {
    return (
        <div className="fixed top-0 left-0 z-50 w-full bg-light-200 px-8 py-4 dark:bg-dark-500 lg:px-20 xl:px-36">
            <div className="flex items-center justify-between text-white">
                <ThemeSwitcher theme={theme} setTheme={setTheme} />
                <ul className="hidden md:flex">
                    {NavbarItems.map(({ href, title }, index) => (
                        <NavbarItem key={index} href={href} title={title} />
                    ))}
                </ul>
                <Resume />
            </div>
        </div>
    )
}
