import { useEffect, useState } from 'react'
import {
    Awards,
    Certifications,
    Footer,
    Hero,
    Navbar,
    Projects,
    Skills,
} from './layouts'
import { Themes } from './types'

function App() {
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })
    const [theme, setTheme] = useState(Themes.LIGHT)

    const toggle = () => {
        const theme = localStorage.getItem('theme')
        if (theme === Themes.DARK) {
            document.documentElement.classList.remove(Themes.DARK)
            localStorage.setItem('theme', Themes.LIGHT)
            setTheme(Themes.LIGHT)
        } else {
            document.documentElement.classList.add(Themes.DARK)
            localStorage.setItem('theme', Themes.DARK)
            setTheme(Themes.DARK)
        }
    }

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    const checkTheme = () => {
        let localStorageTheme = localStorage.getItem('theme')
        if (!localStorageTheme) {
            localStorage.setItem('theme', Themes.LIGHT)
            document.documentElement.classList.add(Themes.LIGHT)
        } else {
            setTheme(
                localStorageTheme === Themes.LIGHT ? Themes.LIGHT : Themes.DARK,
            )
            if (localStorageTheme === Themes.DARK)
                document.documentElement.classList.add(Themes.DARK)
        }
    }

    useEffect(() => {
        checkTheme()
        window.addEventListener('resize', handleResize, false)
    }, [])

    return (
        <div className="bg-light-200 px-6 dark:bg-dark-500 lg:px-20 xl:px-36">
            <Navbar theme={theme} setTheme={toggle} />
            <Hero theme={theme} />
            <Skills width={dimensions.width} height={dimensions.height} />
            {/* <Awards width={dimensions.width} height={dimensions.height} /> */}
            <Certifications />
            <Projects />
            <Footer />
        </div>
    )
}

export default App
