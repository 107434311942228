export const Copyright = () => (
    <p className="text-center text-sm font-light">
        Copyright © 2023{' '}
        <a
            href="#"
            target="_blank"
            rel="noreferrer"
            className="opacity-75 transition-opacity hover:opacity-50"
        >
            Codezema
        </a>
        . All Right Reserved
    </p>
)
